import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Success = () => {
  return (
    <Layout>
      <Seo 
        title="Success"
        description="We have received your message and will reach out shortly!" 
      />
      <div className="container mx-auto pt-36 md:pt-48">
        <div className="mx-auto max-w-5xl md:text-center">
          <h1>Success!</h1>
          <p className="text-xl md:text-2xl md:mx-auto">We have received your message and will reach out shortly!</p>
        </div>
      </div>
    </Layout>
  )
}

export default Success
